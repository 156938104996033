@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--font-gotham: "Gotham";
	}

	@font-face {
		font-family: "Gotham";
		font-weight: 700;
		src: url("../../global/fonts/Gotham-Bold.woff") format("woff");
	}
	@font-face {
		font-family: "Gotham";
		font-weight: 500;
		src: url("../../global/fonts/Gotham-Medium.woff") format("woff");
	}
	@font-face {
		font-family: "Gotham";
		font-weight: 400;
		src:
			url("../../global/fonts/Gotham-Light.woff") format("woff"),
			url("../../global/fonts/Gotham-Light.woff2") format("woff2");
	}
}

@layer components {
	.pill-base {
		@apply block py-[5px] px-4 font-bold transition-colors cursor-pointer rounded-3xl;
	}

	.pill {
		@apply pill-base xl:h-[50px] xl:py-3 xl:border-2 to-xl:leading-tighter to-xl:text-24 to-xl:pl-9;
	}

	.product-form-pill {
		@apply border-2 pill-base;
	}

	.btn {
		@apply flex items-center justify-between rounded bg-green to-lg:w-full h-10 lg:h-15 py-2.5 pl-7.5 pr-3 text-white gap-6;
	}

	.btn--inverted {
		@apply text-black bg-white border border-2 border-grey-light;
	}

	.btn--product-form {
		@apply px-4 py-2 font-bold rounded cursor-pointer;
	}

	.btn--product-form:not(.active) {
		@apply border-2 border-grey-light;
	}

	.btn--product-form.active {
		@apply text-white bg-green;
	}

	.expanded-click-area {
		@apply relative after:absolute after:z-50 after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:h-[150%] after:w-[150%];
	}

	.fancy-underline {
		@apply underline transition-colors duration-300 group-hover:decoration-green hover:decoration-green decoration-transparent underline-offset-4;
	}

	.link {
		@apply font-medium underline decoration-green lg:underline-offset-6 underline-offset-4 decoration-2;
	}

	.icon {
		@apply w-5 h-5 xl:w-10 xl:h-10;
	}

	.product-metadata {
		@apply to-xl:leading-none to-xl:flex to-xl:flex-col to-xl:[&>span]:text-16 to-xl:text-[0px];
	}

	.rich-text a {
		@apply link;
	}

	/* prose */
	.prose {
		@apply max-w-full;
	}

	.prose .rich-text {
		@apply max-w-[1000px] leading-tight lg:leading-content;
	}

	.prose .rich-text img {
		@apply rounded-small;
	}

	.prose .rich-text iframe {
		@apply w-full h-full aspect-video;
	}

	.prose .rich-text > p:first-child {
		@apply mt-0;
	}

	.prose .rich-text > p:last-child {
		@apply mb-0;
	}

	.prose .rich-text:first-child > h2:first-child,
	.prose .rich-text:first-child > h3:first-child {
		@apply mt-0;
	}

	.prose .rich-text > p {
		@apply my-5 lg:my-6;
	}

	.prose .rich-text > h2 + p,
	.prose .rich-text > h3 + p,
	.prose .rich-text > h4 + p {
		@apply mt-0;
	}

	.prose .rich-text > h2,
	.prose .rich-text > h3,
	.prose .rich-text > h4 {
		@apply mb-2 lg:mb-5 text-20 lg:text-24;
	}

	/* DaisyUI */
	.dui-collapse-title:after {
		@apply !hidden;
	}

	.dui-collapse-arrow:not(.collapse-close)
		input[type="radio"]:checked
		~ .dui-collapse-title
		svg {
		@apply rotate-180;
	}

	.dui-dropdown-content {
		@apply px-0 pl-0 drop-shadow-dropdown;
	}

	/* Search dropdown */
	.search-dropdown:focus-within {
		@apply bg-green border-green;
	}

	.search-dropdown:focus-within > label {
		@apply text-white;
	}

	.search-dropdown:focus-within > label > div {
		@apply text-white rotate-180;
	}

	/* Search select */
	.search-select-item {
		@apply relative flex to-lg:leading-tighter to-lg:text-24 to-lg:pl-9 to-lg:py-1;
	}

	.search-select-tick {
		@apply absolute left-0 hidden -translate-y-1/2 icon top-1/2 to-lg:peer-checked:block;
	}

	.search-select-label {
		@apply cursor-pointer lg:peer-checked:underline decoration-green;
	}

	/* noUI range slider */
	.noUi-target {
		@apply !h-0.5 !border-none !bg-green-light lg:!bg-grey-light mt-16 lg:mt-11 mr-5 mb-[10px];
	}

	.noUi-target .noUi-tooltip {
		@apply p-0.5 font-bold leading-none border-none;
	}

	.noUi-target .noUi-connect {
		@apply bg-white lg:bg-green;
	}

	.noUi-target .noUi-handle {
		@apply !w-5 !h-5 border-none rounded bg-white lg:bg-green !shadow-none !top-[-9px] cursor-pointer;
	}

	.noUi-target .noUi-handle:before,
	.noUi-target .noUi-handle:after {
		@apply hidden;
	}

	.noUi-target .noUi-base .noUi-tooltip {
		@apply left-0 transform-none to-lg:text-white to-lg:bg-transparent;
	}

	.noUi-target .noUi-base .noUi-origin:last-child .noUi-tooltip {
		@apply right-0 left-auto;
	}

	/* Swiper */
	.swiper-container {
		@apply relative overflow-hidden max-w-screen;
	}

	.swiper-vertical .swiper-wrapper {
		@apply flex-col;
	}

	.swiper-wrapper {
		@apply relative flex w-full h-full;
	}

	.swiper-slide {
		@apply relative w-full h-full shrink-0 touch-none;
	}

	.swiper-button-next:not(.swiper-button-disabled),
	.swiper-button-prev:not(.swiper-button-disabled) {
		@apply drop-shadow-swiperButton translate-z-0;
	}

	.swiper-button-next.swiper-button-disabled,
	.swiper-button-prev.swiper-button-disabled {
		@apply opacity-0 pointer-events-none;
	}

	[x-cloak] {
		@apply !hidden;
	}

	.htmx-request {
		@apply opacity-50 cursor-wait pointer-events-none;
	}

	.htmx-loading:not(.htmx-request) {
		@apply hidden;
	}

	.htmx-loading.htmx-request .loading-bar {
		@apply -left-full animate-load;
	}

	.htmx-loading.htmx-request ~ div {
		@apply hidden opacity-0;
	}

	.home-page main {
		@apply overflow-x-hidden;
	}

	.product-modal .swiper-slide {
		@apply m-auto;
	}

	.product-modal .swiper-slide picture {
		@apply p-4 drop-shadow-book;
	}

	.product-modal #product-images-slider .icon {
		@apply hidden;
	}

	footer hr {
		@apply border-green;
		margin: 1rem 0;
	}
}
